<template>
  <div>
    Redirecting...
  </div>
</template>

<script>
export default {
  mounted () {
    this.redirectStoreAppLink()
  },
  methods: {
    redirectStoreAppLink () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/android/i.test(userAgent)) {
        window.location.href = 'https://vilearn.vn/'
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://vilearn.vn/'
      } else {
        window.location.href = 'https://vilearn.vn/'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="ml-blog-detail mb-5">
    <div class="container" v-if="detail">
      <div class="row align-items-center ">
        <div class="col-sm-8">
          <div class="breadcrumd-custom">
            <nuxt-link :to="isBlogApp ? '/blog-app' : '/blog'">Blog</nuxt-link>
            <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" />
            <nuxt-link :to="(isBlogApp ? '/blog-app' : '/blog') + '/category/'+detail.category[0].slug">{{detail.category[0].name}}</nuxt-link>
            <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" />
            <span class="active">Chi tiết</span>
          </div>
        </div>
        <div class="col-sm-4">
          <FormSearch :value="keyword" @enter="searchData" placeholder="Tìm bài viết"/>
        </div>
      </div>
      <div class="pt-3">
        <div class="row ">
          <div class="col-lg-8">
            <div class="content shadow-none" v-if="detail">
              <h1 class="blog-name my-0">{{detail.title}}</h1>
              <div class="blog-time">{{ detail.created_at | parseTime('{h}:{i}  {d}/{m}/{y}') }}</div>
              <div class="blog-description" v-html="detail.description"></div>
              <div class="blog-content" v-html="detail.content"></div>
              <div class="blog-share">Chia sẻ</div>
              <ShareComponent :text="detail.title" :link="$route.fullPath"/>
            </div>
            <div class="d-block d-lg-none">
              <div class="event-new shadow-none" v-if="listEvent.length">
                <div class="title font-weight-bold text-uppercase">Sự kiện mới</div>
                <div class="event-list">
                  <div class="event-item" v-for="event in listEvent" :key="event.id"><nuxt-link :to="(isBlogApp ? '/blog-app/' : '/blog/') + event.slug" :title="event.title">{{event.title}}</nuxt-link></div>
                </div>
              </div>
            </div>
            <div class="relations shadow-none" v-if="relations.length">
              <div class="relation-title text-uppercase font-weight-bold">Bài viết liên quan</div>
              <div class="relation-list shadow-none">
                <BlogItem v-for="item in relations" :key="item.id" :relation="true" :item="item"/>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-none d-lg-block">
            <div class="event-new" v-if="listEvent.length">
              <div class="title">Sự kiện mới</div>
              <div class="event-list">
                <div class="event-item" v-for="event in listEvent" :key="event.id"><nuxt-link :to="(isBlogApp ? '/blog-app/' : '/blog/') + event.slug" :title="event.title">{{event.title}}</nuxt-link></div>
              </div>
            </div>
            <div class="d-none d-lg-block banner-right mt-3" v-if="$store.state.banner.find(banner => banner.code === 'BANNER_RIGHT')">
              <BannerItem type="BANNER_RIGHT"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlogItem from '~/components/mlearn/blog/item.vue'
import BannerItem from '~/components/mlearn/banner/item.vue'
import ShareComponent from '~/components/mlearn/share/index.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Resource from '~/common/api/resource'
const blogBySlugResource = new Resource('blog/article/getBySlug')
const lastEventResource = new Resource('blog/article/lastEvent')
const blogRelationResource = new Resource('blog/article/related')
export default {
  components: {
    BlogItem,
    BannerItem,
    ShareComponent,
    FormSearch
  },
  layout: 'headersub',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const { data } = await blogBySlugResource.list({ slug: params.slug })
      .catch(() => {
        return error({ statusCode: 404, title: 'Bài viết không tồn tại', message: 'Bài viết đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!data) {
      return error({ statusCode: 404, title: 'Bài viết không tồn tại', message: 'Bài viết đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const detail = data
    const metaSeo = {
      title: detail?.title,
      description: detail?.description || '',
      keywords: '',
      image: detail?.thumbnail || '',
      url: process.env.NUXT_ENV_WEB_URL + route.fullPath
    }
    const structuredData = {}
    return {
      detail,
      metaSeo,
      structuredData
    }
  },
  data () {
    return {
      keyword: '',
      listEvent: [],
      relations: []
    }
  },
  head () {
    return {
      title: this.metaSeo.title,
      meta: [
        { hid: 'title', name: 'title', content: this.metaSeo.title },
        { hid: 'description', name: 'description', content: this.metaSeo.description },
        { hid: 'keywords', name: 'keywords', content: this.metaSeo.keywords },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: this.metaSeo.title },
        { hid: 'og:url', property: 'og:url', content: this.metaSeo.url },
        { hid: 'og:description', property: 'og:description', content: this.metaSeo.description },
        { hid: 'og:image', property: 'og:image', content: this.metaSeo.image },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: this.metaSeo.title },
        { hid: 'twitter:url', property: 'twitter:url', content: this.metaSeo.url },
        { hid: 'twitter:description', property: 'twitter:description', content: this.metaSeo.description },
        { hid: 'twitter:image', property: 'twitter:image', content: this.metaSeo.image }
      ],
      link: [
        { rel: 'canonical', href: this.metaSeo.url }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    isBlogApp () {
      return /blog-app/.test(this.$route.name)
    }
  },
  created () {
    this.lastEvent()
    this.loadRelation()
  },
  methods: {
    searchData (keyword) {
      this.keyword = keyword
      if (!this.keyword) { return }
      this.$router.push((this.isBlogApp ? '/blog-app' : '/blog') + '/search?q=' + encodeURIComponent(this.keyword))
    },
    lastEvent () {
      lastEventResource.list()
        .then((res) => {
          this.listEvent = res.data
        })
    },
    loadRelation () {
      const query = {
        article_id: this.detail.id,
        category_id: this.detail.category[0].id
      }
      blogRelationResource.list(query)
        .then((res) => {
          this.relations = res.data
        })
    }
  }
}
</script>

<style lang="scss">
.ml-blog-detail{
  background: var(--white);
  @media (min-width: 992px){
    background: unset;
  }
  @media (min-width: 1366px){}
  .content{
    padding:0 15px 32px 15px;
    border-bottom: 6px solid #E2E2E2;
    background: var(--white);
    border-radius: 0px;
    margin-bottom:0px;
    @media (min-width: 992px){
      padding:15px 15px 32px 15px;
      border-bottom: 0;
      box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
      border-radius: 4px;
      margin-bottom: 10px;
    }
    @media (min-width: 1366px){
      padding:16px 24px 30px 24px;
    }
    .blog-name{
      font-weight: 500;
      font-size: 22px;
      @media (min-width: 992px){
        font-size: 24px;
      }
      @media (min-width: 1366px){
        font-size: 32px;
      }
    }
    .blog-time{
      font-size: 14px;
      color: #69696A;
      @media (min-width: 992px){}
      @media (min-width: 1366px){}
    }
    .blog-description{
      padding-top:5px;
      font-size: 16px;
      @media (min-width: 992px){}
      @media (min-width: 1366px){
        font-size: 17px;
      }
    }
    .blog-content{
      font-size: 16px;
      padding-top: 0.5rem;
      @media (min-width: 992px){}
      @media (min-width: 1366px){
        padding-top: 1rem;
        font-size: 17px;
      }
      ul {
        padding: revert;
      }
    }
    .blog-share{
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 16px;
      color: #75777B;
      @media (min-width: 992px){}
      @media (min-width: 1366px){}
    }
  }
  .relations{
    background: var(--white);
    padding:18px 15px;
    border-radius: 0;
    margin-bottom: 0px;
    @media (min-width: 992px){
      border-bottom:0;
      margin-bottom: 10px;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
    }
    @media (min-width: 1366px){
      margin-top: 20px;
      padding:16px 24px 32px 24px;
    }
    .relation-title{
      font-size: 16px;
      @media (min-width: 992px){}
      @media (min-width: 1366px){
        font-size: 20px;
      }
    }
    .relation-list{
      margin-top: 10px;
      background: var(--white);
      border-radius: 0;
      .blog-item{
        margin-top: 10px;
        padding-bottom:20px;
        border-bottom:1px solid #E2E2E2;
        &:first-child{
          margin-top: 0px;
        }
        &:last-child{
          margin-bottom: 10px;
          padding-bottom: 0;
          border-bottom: 0;
          @media (min-width: 992px){}
          @media (min-width: 1366px){}
        }
      }
    }
  }
  .event-new{
    padding: 15px;
    background: var(--white);
    border-radius: 0;
    border-bottom: 6px solid #E2E2E2;
    @media (min-width: 992px){
      padding:18px 15px;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
      border-bottom: 0;
    }
    @media (min-width: 1366px){
      padding:18px 24px;
    }
    .title{
      font-size: 16px;
      @media (min-width: 992px){}
      @media (min-width: 1366px){
        font-size: 20px;
      }
    }
    .event-list{
      .event-item{
        padding:10px 0;
        border-bottom: 1px solid #E2E2E2;
        @media (min-width: 1366px){
          font-size: 17px;
        }
        &:first-child{
          padding-top: 1rem;
          @media (min-width: 1366px){
            padding-top: 1.5rem;
          }
        }
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
}
</style>

<template>
  <div class="ml-quiz mb-5">
    <div class="container" v-if="detail">
      <div class="row">
<!--        <div class="col-lg-2 d-none d-lg-block">-->
<!--          <MenuLeft @selectClass="findClass" :selected-id="query.class_id"/>-->
<!--        </div>-->
        <div class="col-lg-12">
          <div class="filter-quiz d-flex justify-content-start">
            <CategorySelect class="d-none d-lg-block mr-2" placeholder="Môn" @findCategory="findCategory" :selected-id="query.category_id" :library-show="true"/>
            <ExampTypeList class="d-none" @findExamType="findExamType" placeholder="Loại đề"/>
            <ExampTimeList class="d-none" @findExamTime="findExamTime" placeholder="Thời gian"/>
            <ExampGroupList class="d-none" @findExamGroup="findExamGroup" placeholder="Chuyên đề"/>
            <FormSearch class="ml-auto" :value="query.keyword" @enter="handerSearch" />
          </div>
          <div v-if="keyword" class="text-primary text-break mt-3">
            {{ query.total }} kết quả tìm kiếm cho "<b>{{ keyword }}</b>"
          </div>
          <div class="form-content mt-3">
            <ViewMode :title="detail.name" @mode="changeMode" />
            <div class="row list-item">
              <QuizItem v-for="(item) in listData" :key="item.id" :class="viewList ? 'col-sm-6 col-md-4 col-xl-3' : 'col-12'" :item="item" :view-list="viewList" :playlist="playlist" @createdPlaylist="loadPlayList()" @showNotify="showNotify"/>
            </div>
            <NoData v-if="!listData.length && !loading" text="Nội dung đang cập nhật"/>
            <Pagination class="mt-3" :total="query.total" :value.sync="query.page" @change="loadData" :limit="query.limit" />
          </div>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import { EventBus } from '~/common/utils/eventBus'
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import CategorySelect from '~/components/mlearn/select/category.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Pagination from '~/components/mlearn/pagination/index.vue'
import ExampTypeList from '~/components/mlearn/select/examType.vue'
import ExampTimeList from '~/components/mlearn/select/examTime.vue'
import ExampGroupList from '~/components/mlearn/select/examGroup.vue'
import QuizItem from '~/components/mlearn/quiz/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'
const examResource = new Resource('library/exam')
const playlistResource = new Resource('library/playlist')
const topicDetailResource = new Resource('library/groupExam/getBySlug')
export default {
  components: {
    // MenuLeft,
    ViewMode,
    CategorySelect,
    FormSearch,
    Pagination,
    ExampTypeList,
    ExampTimeList,
    ExampGroupList,
    QuizItem,
    PlaylistNotify
  },
  layout: 'mlearn',
  asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    const slug = params.slug
    const query = {
      slug,
      category_id: '',
      class_id: '',
      exam_type_id: '',
      keyword: '',
      page: 1,
      limit: 12,
      total: 0
    }
    const storeData = store.state.storeRedirect
    if (storeData) {
      query.category_id = storeData.category_id || ''
      query.class_id = storeData.class_id || ''
    }
    const listData = []
    const playlist = []
    const metaSeo = null
    const structuredData = {}
    return {
      detail: null,
      listData,
      query,
      playlist,
      loading: false,
      flagNotify: false,
      textNotify: '',
      keyword: '',
      metaSeo,
      structuredData,
      viewList: true
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  created () {
    EventBus.$on('handleChangeClassMenu', this.handleChangeClassMenu)
    this.loadPlayList()
    this.loadDetail()
    this.$store.dispatch('setStoreRedirect', null)
  },
  destroyed () {
    EventBus.$off('handleChangeClassMenu', this.handleChangeClassMenu)
  },
  mounted () {
    if (localStorage.getItem('quiz')) {
      this.showNotify(`Đã lưu bài làm <b>${localStorage.getItem('quiz')}</b> vào Thư viện của tôi`)
      localStorage.removeItem('quiz')
    }
  },
  methods: {
    async loadDetail (page = 1) {
      this.query.page = page
      const data = await topicDetailResource.list(this.query)
      if (!data) {
        this.$router.push('/khong-ton-tai')
        return
      }
      this.detail = data.data
      this.listData = this.detail.exams
      this.query.total = data.total
    },
    changeMode (mode) {
      this.viewList = mode
    },
    findClass (classId) {
      this.query.class_id = classId
      this.query.total = 0
      this.loadData()
    },
    findCategory (categoryId) {
      this.query.category_id = categoryId
      this.query.total = 0
      this.loadData()
    },
    findExamType (examType) {
      this.query.exam_type_id = examType
      this.query.total = 0
      this.loadData()
    },
    findExamTime (value) {
      this.query.time = value
      this.query.total = 0
      this.loadData()
    },
    findExamGroup (examGroup) {
      this.query.set_exam_id = examGroup
      this.query.total = 0
      this.loadData()
    },
    async loadData (page = 1) {
      this.query.page = page
      if (!this.keyword) {
        this.loadDetail(page)
        return
      }
      if (this.loading) { return }
      this.loading = true
      const data = await examResource.list(this.query)
      this.listData = data.data || []
      this.query.total = data.total || 0
      this.keyword = this.query.keyword
      this.loading = false
    },
    handerSearch (keyword) {
      this.query.total = 0
      this.keyword = keyword
      this.query.keyword = keyword
      this.loadData()
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list({ type: 'exam' })
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    handleChangeClassMenu (obj) {
      this.findClass(obj.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-quiz{
    .form-content{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 12px;
      padding:20px;
      @media (max-width:1366px) {
        padding:18px;
      }
      @media (max-width:576px) {
        padding:10px;
      }
      .list-item{
        > [class*="col-"] {
          padding-top: 20px;
          @media (max-width:1366px) {
            padding-top: 16px;
          }
          @media (max-width:576px) {
            padding-top: 10px;
          }
          &.col-12{
            padding-top: 0;
            border-bottom: 1px solid #EBEBEB;
            &:last-child{
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
